"use client";
import { LoginForm } from "@/app_components/auth/LoginForm";


export function LoginPage() {
    return (
        <LoginForm backPieces={[{ label: "Home", href: "/" }]} />
    );
}

